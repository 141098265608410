import { createApp } from "vue";
import App from "./App.vue";
import { createVuetify } from "vuetify";
import router from "@/router/router.js";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
//import { initializeApp } from "firebase/app";
//import { getFirestore } from "firebase/firestore";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { useStore } from "/src/components/store";

import {
  faUser,
  faCogs,
  faBriefcase,
  faGraduationCap,
  faRss,
  faFileAlt,
  faAddressBook,
  fa3,
  faDatabase,
  faGlobe,
  faComputer,
  faPlus,
  faDna,
  faCode,
  faDiagramProject
} from "@fortawesome/free-solid-svg-icons";
import {
  faJava,
  faAndroid,
  faPhp,
  faPython,
  faJs,
  faRProject,
  faUncharted,
  faLinkedin,
  faGithub,
  faAppStoreIos
} from "@fortawesome/free-brands-svg-icons";
library.add(
  faUser,
  faCogs,
  faBriefcase,
  faGraduationCap,
  faRss,
  faFileAlt,
  faAddressBook,
  faJava,
  faAndroid,
  faPhp,
  faPython,
  fa3,
  faDatabase,
  faJs,
  faRProject,
  faGlobe,
  faComputer,
  faPlus,
  faDna,
  faUncharted,
  faLinkedin,
  faGithub,
  faCode,
  faDiagramProject,
  faAppStoreIos
);

const firebaseConfig = {
  apiKey: "AIzaSyCFmqxURBgj-iJN6gphYU-DVbobPoDDYHg",
  authDomain: "lisa-hoshizuki-resume.firebaseapp.com",
  projectId: "lisa-hoshizuki-resume",
  storageBucket: "lisa-hoshizuki-resume.appspot.com",
  messagingSenderId: "577622152124",
  appId: "1:577622152124:web:400a5e22f04c0ea71491b9",
  measurementId: "G-PD79Q69K04"
};
const vuetify = createVuetify({
  components,
  directives,
  theme: { defaultTheme: "light" }
});

const app = createApp(App);
const store = useStore();

//dependency firebase 10.5.2
app.config.globalProperties.$store = store;
//const firebaseApp = initializeApp(firebaseConfig);
//const firestore = getFirestore(firebaseApp);
//app.config.globalProperties.$firestore = firestore;
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.use(vuetify);
app.mount("#app");
